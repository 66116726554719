/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:eff50538-2c9b-4604-885b-e85e5d673994",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_7mO6ma7gW",
    "aws_user_pools_web_client_id": "6ji4qe9810hpg3th5f1gj3egdd",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "adminapi",
            "endpoint": "https://4uywpc2pdg.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
