import React from 'react'

function techsupport() {
  return (
    <div className='techsupport'>
      <p></p>
      <center><h1>Tech Support</h1>
      Email: xiaowenzhang@vrv.earth</center>
    </div>
  );
}


export default techsupport;
