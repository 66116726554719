// Products.js
import React from 'react';
import { Table } from 'react-bootstrap';
import { API } from 'aws-amplify'

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      dishes: []
    }
  }

  //call api
  async callApi() {
    const requestInfo = {
      queryStringParameters: {
        endpoint: 'OrderInfo'
      }
    }
    const response = await API.get('adminapi', '/adminapi', requestInfo)
    console.log(response);

    this.setState({
      products: response.productsWithQuantity,
      dishes: response.dishesWithQuantity
    });
  }

  componentDidMount() {
    this.callApi()

  }

  render() {
    const { error, products, dishes } = this.state;
    if (error) {
      return (
        <div>Error: {error.message}</div>
      )
    } else {
      return (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <h3>Sets Report</h3>
                    <form action="#" method="get" className="sbox-title">
                      <div className="input-group">
                        <input type="text" name="q" className="form-control" placeholder="Search..." />
                        <span className="input-group-btn">
                          <button type="submit" name="search" id="search-btn" className="btn btn-flat"><i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </form>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(products).map(product => (
                        <tr key={product.sku}>
                          <td>{product.sku}</td>
                          <td>{product.title}</td>
                          <td>{product.quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <h3>Dishes Report</h3>
                  <Table>
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Dish Name</th>
                        <th>Ordered Quantity</th>
                        <th>Inventory Quantity</th>
                        <th>Inventory in U Place</th>
                        <th>Inventory in Bellevue International School</th>
                        <th>Inventory in Microsoft Main Campus</th>
                        <th>Inventory in 120 Deli</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(dishes).map(dish => (
                        <tr key={dish.sku}>
                          <td>{dish.sku}</td>
                          <td>{dish.title}</td>
                          <td>{dish.quantity}</td>
                          <td>{dish.inventory_quantity}</td>
                          <td>{dish.inventory_in_u_place}</td>
                          <td>{dish.inventory_in_bellevue_school}</td>
                          <td>{dish.inventory_in_msft}</td>
                          <td>{dish.inventory_in_120_deli}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
    }
  }
}

export default Products;
