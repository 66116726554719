// VRVDashboard.js
import React from 'react';
import { Progress } from 'antd';
import { API } from 'aws-amplify'

class VRVDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      orders: []
    }
  }

   //call api
  async callApi() {
    const requestInfo = {
      queryStringParameters: {
        endpoint: 'OrderInfo'
      }
    }
    const response = await API.get('adminapi', '/adminapi', requestInfo)
    console.log(response);

    this.setState({
      products: response.productsWithQuantity,
      dishes: response.dishesWithQuantity
    });
  }

  componentDidMount() {
    this.callApi()

  }

  render() {
    const { error, orders } = this.state;
    if (error) {
      return (
        <div>Error: {error.message}</div>
      )
    } else {
      return (
        <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <h3>Products with SKU Active</h3>
                <Progress percent={30} />
                <Progress percent={50} status="active" />
                <Progress percent={70} status="exception" />
                <Progress percent={100} />
                <Progress percent={50} showInfo={false} />
                <Progress
                type="circle"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                percent={90}
              />
              <Progress
                type="circle"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                percent={100}
              />
            </div>
        </div>
        </div>
        </section>
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <h3>Products with SKU Draft</h3>
                <Progress percent={60} />
                <Progress percent={70} status="draft" />
                <Progress percent={70} status="exception" />
                <Progress percent={100} />
                <Progress percent={50} showInfo={false} />
                <Progress
                type="circle"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                percent={60}
              />
              <Progress
                type="circle"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                percent={100}
              />
            </div>
        </div>
        </div>
        </section>
        </div>
      
        
  
      )
    }
  }
}

export default VRVDashboard;

