// Products.js
import React from 'react';
import { Table } from 'react-bootstrap';
import { API, Auth } from 'aws-amplify'
import { Collapse } from 'antd';

const { Panel } = Collapse;

class DriverView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      orders: [],
      webConfigs: null
    }
  }

  async callApi() {
    const requestInfo = {
      queryStringParameters: {
        endpoint: 'OrdersForDriver',
        userEmail: Auth.user.attributes.email
      }
    }
    const response = await API.get('adminapi', '/adminapi', requestInfo)
    this.setState({
      error: response.error,
      orders: response.orders,
      webConfigs: response.webConfigs
    })
    console.log(response);
  }

  componentDidMount() {
    this.callApi()
  }

  render() {
    const { error, orders, webConfigs } = this.state;
    if (error) {
      return (
        <div>Error: {error.message}</div>
      )
    } else {
      return (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <h3>Hello, {Auth.user.attributes.email}</h3>
                  <h3> My orders to deliver</h3>
                  <Table>
                    <thead>
                      <tr>
                        <th>Order No.</th>
                        <th>Customer</th>
                        <th>Shipping Address</th>
                        <th>Financial Status</th>
                        <th>Status</th>
                        <th>Assign Driver</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map(order => (
                        <tr key={order.id}>
                          <td><a href={webConfigs.shopifyAdminUrlPrefix + 'orders' + order.id}>{order.order_number}</a></td>
                          <td>{order.customer.first_name + ' ' + order.customer.last_name}</td>
                          <td>
                            <div>{order.shipping_address.address1}</div>
                            <div>{order.shipping_address.address2}</div>
                            <div>{order.shipping_address.city}</div>
                          </td>
                          <td>{order.financial_status}</td>
                          <td>{order.tags.vrvStatus}</td>
                          <td>{order.tags.vrvDriver}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
    }
  }
}

export default DriverView;


