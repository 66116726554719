import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as MdIcons from 'react-icons/md';

export const SidebarData = [

  {
    title: 'VRV Dashboard',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },

  {
    title: 'Order List',
    path: '/orderlist',
    icon: <FaIcons.FaRegListAlt />,
    cName: 'nav-text'
  },

  {
    title: 'Products List',
    path: '/products',
    icon: <FaIcons.FaProductHunt />,
    cName: 'nav-text'
  },

  {
    title: 'Products Info',
    path: '/productsinfo',
    icon: <MdIcons.MdStorage />,
    cName: 'nav-text'
  },

  {
    title: 'Driver View',
    path: '/driverview',
    icon: <MdIcons.MdStorage />,
    cName: 'nav-text'
  },

  {
    title: 'Inventory List',
    path: '/inventorylist',
    icon: <FaIcons.FaCartPlus />,
    cName: 'nav-text'
  },


  {
    title: 'Tech Support',
    path: '/techsupport',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  },

  {
    title: '',
    cName: 'nav-text'
  },


];
