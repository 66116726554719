// Products.js
import React from 'react';
import { Table } from 'react-bootstrap';
import { API } from 'aws-amplify'
import { Collapse } from 'antd';

const { Panel } = Collapse;

class ProductsInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      productsWithoutSku: [],
      skuDetails: { 'sets': [], 'dishes': [], 'units': [] },
    }
  }

  //call api
  async callApi() {
    const requestInfo = {
      queryStringParameters: {
        endpoint: 'ProductInfo'
      }
    }
    const response = await API.get('adminapi', '/adminapi', requestInfo)
    console.log(response);
    this.setState({
      skuDetails: response.productInfo.skuDetails,
      productsWithoutSku: response.productInfo.productsWithoutSku,
    })
  }

  componentDidMount() {
    this.callApi()
  }

  render() {
    const { errors, productsWithoutSku, skuDetails } = this.state;
    if (errors) {
      return (
        <div>Error: {errors.map(error => {
          <div>{error}</div>
        })}
        </div>
      )
    } else {
      return (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <h3>SKU Details</h3>
                  <h4>Sets</h4>
                  <Collapse>
                    {skuDetails.sets.map(skuItem => (
                      <Panel header={skuItem.sku + ': ' + skuItem.title} key={skuItem.sku}>
                        {skuItem.products.map(skuProduct => (
                          <div>{skuProduct.title} <a href={skuProduct.shopifyLink} target="_blank">(shopify link)</a></div>
                        ))}
                        <Collapse>
                          {skuItem.children.map(childLevel1 => (
                            <Panel header={childLevel1.sku + ': ' + childLevel1.title} key={skuItem.sku + ":" + childLevel1.sku}>
                              <Collapse>
                                {childLevel1.children.map(childLevel2 => (
                                  <Panel header={childLevel2.sku + ': ' + childLevel2.title} key={skuItem.sku + ":" + childLevel1.sku + ":" + childLevel2.sku}></Panel>
                                ))}
                              </Collapse>
                            </Panel>
                          ))}
                        </Collapse>

                      </Panel>
                    ))}
                  </Collapse>
                  <h4>Dishes</h4>
                  <Collapse>
                    {skuDetails.dishes.map(skuItem => (
                      <Panel header={skuItem.sku + ': ' + skuItem.title} key={skuItem.sku}>
                        {skuItem.products.map(skuProduct => (
                          <div>{skuProduct.title} <a href={skuProduct.shopifyLink} target="_blank">(shopify link)</a></div>
                        ))}
                        <Collapse>
                          {skuItem.children.map(childLevel1 => (
                            <Panel header={childLevel1.sku + ': ' + childLevel1.title} key={skuItem.sku + ":" + childLevel1.sku}>
                              <Collapse>
                                {childLevel1.children.map(childLevel2 => (
                                  <Panel header={childLevel2.sku + ': ' + childLevel2.title} key={skuItem.sku + ":" + childLevel1.sku + ":" + childLevel2.sku}></Panel>
                                ))}
                              </Collapse>
                            </Panel>
                          ))}
                        </Collapse>

                      </Panel>
                    ))}
                  </Collapse>
                  <h4>Units</h4>
                  <Table>
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Name</th>
                        <th>Shopify Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {skuDetails.units.map(unit => (
                        <tr key={unit.sku}>
                          <td>{unit.sku}</td>
                          <td>{unit.title}</td>
                          <td>
                            <a href={unit.shopifyLink} target="_blank">Shopify Link</a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <h3>Products without SKU</h3>
                  <Table>
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Status</th>
                        <th>Shopify Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productsWithoutSku.map(product => (
                        <tr key={product.title}>
                          <td>{product.title}</td>
                          <td>{product.status}</td>
                          <td>
                            <a href={product.shopify_link} target="_blank">Shopify Link</a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
    }
  }
}

export default ProductsInfo;


