//Inventory.js
import React from 'react';
import { Table } from 'react-bootstrap';
import { a, API } from 'aws-amplify'
import Search from '../components/Search';
import { Button } from 'antd';

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.toggleListReverse = this.toggleListReverse.bind(this)
    this.toggleListPrice = this.toggleListPrice.bind(this)
    this.state = {
      errors: null,
      orders: [],
      dishes: [],
      isOldestFirst: true,
      sku: '',
      title: '',
      inventory_quantity: 0
    }
  }

  async callApi() {
    const requestInfo = {
      queryStringParameters: {
        endpoint: 'OrderInfo'
      }
    }
    const response = await API.get('adminapi', '/adminapi', requestInfo)
    this.setState({ orders: response.orders })
    this.setState({ dishes: response.dishesWithQuantity })
    console.log(response);
  }

  componentDidMount() {
    this.callApi();
  }
  
  //sort by price not finish yet
  SortByPrice() {
    const { orders } = this.state
    let neworders = orders
    if (this.state.ishighestFirst) {
      orders.sort(order => order.total_price < 70)
    }
    this.setState({
      isOldestFirst: !this.state.ishigestFirst,
      orders: neworders
    })
  }

  toggleListPrice(event) {
    this.SortByPrice()
  }

  toggleListReverse(event) {
    const { orders } = this.state
    let neworders = orders.reverse()
    this.setState({
      orders: neworders
    })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event) => {
    alert('You submitted text data:' + this.state.sku + this.state.title + this.state.inventory_quantity);
    event.preventDefault();
  }

  render() {
    const { errors, orders, dishes } = this.state;
    if (errors && errors.length > 0) {
      return (
        <div>
          {errors.map(error => {
            <div>{error}</div>
          })}
        </div>
      )
    } else {
      return (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <h3>Dishes Inventory Report</h3>
                    <Search />
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Items</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr onSubmit={this.handleSubmit} className="inputForm">
                      <td>
                      <input type="text" value={this.state.sku}
                      name="sku"
                      onChange={this.handleChange} />
                      </td>
                      <td>
                      <input type="text" value={this.state.title}
                      name="title"
                      onChange={this.handleChange} />
                      </td>
                      <td>
                      <input type="number" value={this.state.inventory_quantity}
                      name="inventory_quantity"
                      onChange={this.handleChange} />
                      </td>
                      <td>
                      <Button type="primary" onClick={this.handleChange}>
                          Add
                      </Button>
                      </td>
                    </tr>
                    {Object.values(dishes).map(dish => (
                        <tr key={dish.sku}>
                          <td>{dish.sku}</td>
                          <td>{dish.title}</td>
                          <td>{dish.inventory_quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
    }
  }
}

export default Inventory;
