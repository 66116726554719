import React, { Component } from 'react'
import Select from 'react-select'
import axios from 'axios'


export default class App extends Component {

constructor(props){
    super(props)
    this.state = {
    selectOptions : [],
    id: "",
    email: ''
    }
}

async getOptions(){
    const res = await axios.get('https://6d60hbxwb4.execute-api.us-east-1.amazonaws.com/dev/orders')
    const data = res.data

    const options = data.map(orders => ({
    "value" : orders.email,
    "label" : orders.id

    }))

    this.setState({selectOptions: options})

}

handleChange(e){
this.setState({email:e.value, id:e.label})
}

componentDidMount(){
    this.getOptions()
}

render() {
    console.log(this.state.selectOptions)
    return (
    <div>
        <Select options={this.state.selectOptions} onChange={this.handleChange.bind(this)} />
    <p>You have selected <strong>{this.state.id}</strong> orders email is <strong>{this.state.email}</strong></p>
    </div>
    )
}
}