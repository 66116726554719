import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import VRVDashboard from './pages/VRVDashboard';
import InventoryList from './pages/InventoryList';
//import Header from './components/Header';
import TechSupport from './pages/TechSupport';
import OrderList from './pages/OrderList';
import ProductsInfo from './pages/ProductsInfo';
import DriverView from './pages/DriverView';
import Products from './pages/Products';
import { withAuthenticator } from '@aws-amplify/ui-react';

function App() {
  //router for pages
  return (
    <>

      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={VRVDashboard} />
          <Route path='/OrderList' component={OrderList} />
          <Route path='/Products' component={Products} />
          <Route path='/ProductsInfo' component={ProductsInfo} />
          <Route path='/DriverView' component={DriverView} />
          <Route path='/TechSupport' component={TechSupport} />
          <Route path='/InventoryList' component={InventoryList} />
        </Switch>
      </Router>

    </>
  );
}

export default withAuthenticator(App);


