// OrderList.js
import React from 'react';
import { Table } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { FaFilter } from 'react-icons/fa';
//import Select from 'react-select';
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.toggleListReverse = this.toggleListReverse.bind(this);
    this.state = {
      webConfigs: null,
      errors: null,
      orders: [],
      possibleStatuses: [],
      selectedOption: null,
      visible: false,
      search: ''
    }
  }

  //call api
  async callApi() {
    const requestInfo = {
      queryStringParameters: {
        endpoint: 'OrderInfo'
      }
    }
    const response = await API.get('adminapi', '/adminapi', requestInfo)
    console.log(response);
    this.setState({
      errors: response.errors,
      orders: response.orders,
      webConfigs: response.webConfigs,
      possibleStatuses: response.possibleStatuses,
    });

  }

  componentDidMount() {
    this.callApi();
  }

  //drawer function
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  //drawer close function
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  
  //select function
  handleChange = selectedOption => {
    this.setState({ selectedOption: selectedOption });
    console.log(`Option selected:`, selectedOption);
  };
  
  //update status function
  setOrderStatus = async () => {
    let ordersId = 3654316654766;
    const requestInfo = {
      queryStringParameters: {
        endpoint: 'SetOrderStatus',
        orderId: ordersId,
        status: this.state.selectedOption
      }
    }
    const response = await API.get('adminapi', '/adminapi', requestInfo)
    console.log(response);
    window.location.reload();
  }

  //sort by price not finish yet
  SortByPrice() {
    const { orders } = this.state
    let neworders = orders
    if (this.state.ishighestFirst) {
      orders.sort(order => order.total_price < 70)
    }
    this.setState({
      isOldestFirst: !this.state.ishigestFirst,
      orders: neworders
    })
  }
  
  toggleListPrice(event) {
    this.SortByPrice()
  }

 //time sort toggle
  toggleListReverse(event) {
    const { orders } = this.state
    let neworders = orders.reverse()
    this.setState({
      orders: neworders
    })
  }

  render() {
    const { errors, orders, possibleStatuses, webConfigs } = this.state;
    const { selectedOption } = this.state;
    const options = possibleStatuses.map(possibleStatuse => ({
      "value": possibleStatuse,
      "label": possibleStatuse
    }))
    //utc to localtime
    const ordersCreated_at = new Date('2021-03-01T11:46:57-08:00');
    console.log(ordersCreated_at.toLocaleString('en-US'));
    if (errors && errors.length > 0) {
      return (
        <div>
          {errors.map(error => {
            <div>{error}</div>
          })}
        </div>
      )
    } else {
      return (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <h3>OrderList Report</h3>
                    <Select
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={options}
                    />
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Items</th>
                        <th>Date<i className="bi bi-filter" onClick={this.toggleListReverse}>
                          <FaFilter></FaFilter>
                        </i></th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map(order => (
                        <tr key={order.id}>
                          <td>{order.id}</td>
                          <td>{order.line_items[0]['title']}</td>
                          <td>{ordersCreated_at.toLocaleString()}</td>
                          <td><input type="checkbox" defaultChecked=
                            {this.state.rememeberme} ></input>Check</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <h3>OrderList Report</h3>
                    <Select
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={options}
                    />
                    <Drawer
                      title=""
                      width={720}
                      onClose={this.onClose}
                      visible={this.state.visible}
                      footer={
                        <div
                          style={{
                            textAlign: 'right',
                          }}>
                          <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                            Cancel
                              </Button>
                          {/*update status button*/}
                          <Button onClick={this.setOrderStatus}  type="submit">
                            Update
                              </Button>
                        </div>}>
                      <Form layout="herical" hideRequiredMark>
                        <br></br><br></br><br></br>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              name="Status"
                              label="Status"
                              rules={[{ required: true, message: 'Please select an Status' }]}
                            >
                              {/* select status button */}
                              <Select
                              value={selectedOption}
                              onChange={this.handleChange}
                              options={options}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="approver"
                              label="Approver"
                              rules={[{ required: true, message: 'Please choose the approver' }]}
                            >
                              <Select placeholder="Please choose the approver">
                                <Option value="jack">Maggie Liu</Option>
                                <Option value="tom">Jaden Liu</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              name="description"
                              label="Description"
                              rules={[
                                {
                                  required: true,
                                  message: 'please enter url description',
                                },
                              ]}>
                              <Input.TextArea rows={4} placeholder="please enter url description" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Drawer>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>Order No.</th>
                        <th>Customer</th>
                        <th>Shipping Address</th>
                        <th>Financial Status</th>
                        <th>Status</th>
                        <th>Assign Driver</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map(order => (
                        <tr key={order.id}>
                          <td><a href={webConfigs.shopifyAdminUrlPrefix + 'orders/' + order.id}>{order.order_number}</a></td>
                          <td>{order.customer.first_name + ' ' + order.customer.last_name}</td>
                          <td>
                            <div>{order.shipping_address === undefined ? null : order.shipping_address.address1}</div>
                            <div>{order.shipping_address === undefined ? null : order.shipping_address.address2}</div>
                            <div>{order.shipping_address === undefined ? null : order.shipping_address.city}</div>
                          </td>
                          <td>{order.financial_status}</td>
                          <td><Button type="primary" onClick={this.showDrawer}><PlusOutlined />{order.tags.vrvStatus}
                          </Button></td>
                          <td>{order.tags.vrvDriver}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
    }
  }
}
export default OrderList;


