import React, { useState } from 'react'
import * as FaIcons from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { SidebarData } from './SidebarData'
import './Navbar.css'
import { IconContext } from 'react-icons'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import {API} from 'aws-amplify'

// signout function with api call
function Navbar() {
  async function callApi(){
    const requestInfo = {}
    const response = await API.get('adminapi', '/adminapi', requestInfo)
    console.log(response);
  }
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
    <header className="main-header">
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
          <Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? 'nav-menu' : 'nav-menu active'}>
          <ul className='nav-menu-items'>
            <li className='navbar-toggle'>
              <center><img src="img/VRV-LOGO-2.png" className="img-circle" alt="User Image" width="160" height="50"  /></center>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
            <AmplifySignOut/>
                <i className="btn btn-flat" onClick={callApi} type="primary"></i>
          </ul>
        </nav>
      </IconContext.Provider> </header>
    </>
    
    
  );
}

export default Navbar;
